<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="4" v-if="!edition && !$route.params.fact">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :PageResponse="'dps'"
          :AllResponse="'allfact'"
          :title="'Demandes de Paiements'"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'factures'"
          :sheetname="'factures'"
          @open="NewFactForm"
          @rowselect="FactChange"
          :showedit="false"
          :key="klist"
          :Add="editer"
          :del="false"
          :search_elm="filtre"
        >
        </listitemspage>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="edition"> </v-col>
      <v-col cols="12" sm="6" md="8">
        <dpprint
          :key="kdp"
          :dp="dp"
          :dp_cat="dp_cat"
          :cmds_list="cmds_list"
          :facts_list="facts_list"
          :avances_list="avances_list"
          :rejected_list="rejected_list"
          @change="FactChange"
          :editer="editer"
          :valider="valider"
          :solder="solder"
          :facturer="facturer"
          :accepter="accepter"
          :modes="modes"
          @refresh="refresh"
          :edition="edition"
          @edition="edit_change"
        >
        </dpprint>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="edition"> </v-col>
    </v-row>

    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import DP from "../graphql/DP/DP.gql";
import ALLDP from "../graphql/DP/ALLDP.gql";
import ACTUAL_CMDS from "../graphql/Facture/ACTUAL_CMDS.gql";
import ACTUAL_FACTS from "../graphql/DP/ACTUAL_FACTS.gql";
import ACTUAL_AVANCES from "../graphql/DP/ACTUAL_AVANCES.gql";
import ACTUAL_REJECTED from "../graphql/DP/ACTUAL_REJECTED.gql";
import MODES from "../graphql/Paiement/MODES.gql";
import UPDATE_DP from "../graphql/DP/UPDATE_DP.gql";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    dpprint: () => import("../components/DPPrint.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    dp_cat: Number,
  },
  data: () => ({
    filtre: null,
    klist: 0,
    edition: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editer: false,
    solder: false,
    valider: false,
    facturer: false,
    accepter: false,
    selitem: {},
    kdp: 100,
    headers: [
      {
        text: "Code",
        value: "nodp",
        enum: "NODP",
        title: true,
        selected: true,
      },
      {
        text: "Type",
        value: "type_name",
        enum: "TYPE_NAME",
        selected: true,
      },
      {
        text: "Ref.",
        value: "nocmd",
        enum: "NOCMD",
        title: true,
        selected: false,
      },
      {
        text: "Date",
        value: "dp_date",
        enum: "DP_DATE",
        slot: "date",
        selected: false,
      },
      {
        text: "Tier",
        align: "text-left",
        value: "tier_name",
        enum: "TIERS_NAME",
        selected: true,
      },

      {
        text: "Montant",
        value: "mont_ttc",
        enum: "MONT_TTC",
        align: "end",
        slot: "cur",
        width: "150",
        selected: true,
      },

      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        expire: true,
        selected: true,
      },
    ],

    dp: {},
    changed: false,
    cmds_list: [],
    facts_list: [],
    avances_list: [],
    rejected_list: [],
    loading: false,
    modes: [],
    modalites: [],
  }),

  computed: {
    Qselect() {
      return DP;
    },
    Qselectall() {
      return ALLDP;
    },
  },
  watch: {
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre;
        this.klist++;
      },
    },
  },
  created() {},
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  async beforeRouteLeave(to, from, next) {
    if (this.$store.state.isEditing) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Quitter sans enregistrement !! Etês-vous sûr ?",
          { color: "orange darken-3" }
        )
      ) {
        await this.update_modif();
        next();
      } else return;
    } else next();
  },
  async mounted() {
    this.editer =
      this.$store.state.auth.includes("02083") ||
      this.$store.state.auth.includes("02107") ||
      this.$store.state.isadmin;
    this.valider =
      this.$store.state.auth.includes("03083") ||
      this.$store.state.auth.includes("03107") ||
      this.$store.state.isadmin;
    this.solder =
      this.$store.state.auth.includes("04083") ||
      this.$store.state.auth.includes("04107") ||
      this.$store.state.isadmin;
    this.accepter =
      this.$store.state.auth.includes("05108") || this.$store.state.isadmin;

    await this.get_data();
    //from payement
    if (this.$route.params.paye) {
      this.selitem = this.$route.params.paye;
      this.mvme = this.selitem;
      this.keymvm++;
      this.klist++;
    }
    //from factures
    if (this.$route.params.fact) {
      let i = this.facts_list.findIndex(
        (elm) => elm.id == this.$route.params.fact.id
      );
      if (i >= 0) {
        this.dp.tier_id = this.facts_list[i].tier_id;
        this.dp.cmd_id = this.facts_list[i].cmd_id;
        this.dp.fact_id = this.$route.params.fact.id;
        this.dp.modepaiement = this.facts_list[i].modepaiement;
        this.dp.delai_paye = this.facts_list[i].delai_paye
          ? this.facts_list[i].delai_paye
          : this.facts_list[i].tier_delai;
      }
      this.dp.id = -1;
      this.edition = true;
      this.kdp++;
    }
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },

  methods: {
    async preventNav(event) {
      if (!this.$store.state.isEditing) return;
      event.preventDefault();
      event.stopImmediatePropagation();

      //event.returnValue = "";
      await this.update_modif();
      //
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.dp.id > 0) {
        let v = {
          dp: {
            id: this.dp.id,
            modif_by: null,
            modif_date: null,
          },
        };

        await this.maj(UPDATE_DP, v);
      }
    },

    async maj(query, v) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },
    edit_change(value) {
      this.edition = value;
    },
    async requette(query, v, f) {
      let r;

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async refresh() {
      let v = {
        where: {
          AND: [
            { column: "FACT_TYPE", value: this.dp_cat },
            { column: "STATUT_ID", value: 14 },
            { column: "DP", value: 0 },
          ],
        },
      };
      let r = await this.requette(ACTUAL_FACTS, v, "no-cache");
      if (r) this.facts_list = r.allfact;

      v = {
        where: {
          AND: [
            { column: "CMD_TYPE", operator: "IN", value: [2, 4] },
            { column: "COUNTRY_ID", value: 13 },
            { column: "STATUT_ID", value: 7 },
          ],
        },
      };
      r = await this.requette(ACTUAL_CMDS, v, "no-cache");
      if (r) {
        this.cmds_list = r.allcmds;
      }
      v = {
        TypeScope: 1,
      };
      r = await this.requette(ACTUAL_AVANCES, v, "no-cache");
      if (r) {
        this.avances_list = r.alldp;
      }

      r = await this.requette(ACTUAL_REJECTED, "", "no-cache");
      if (r) {
        this.rejected_list = r.alldp;
      }
    },
    async get_data() {
      this.loading = true;

      await this.refresh();

      let r = await this.requette(MODES);
      if (r) {
        this.modes = r.modes;
        this.modalites = r.modalites;
      }

      this.loading = false;
    },

    FactChange(item) {
      this.dp = item;
      this.selitem = item;
      this.kdp++;
    },
    NewFactForm(item) {
      this.edition = true;
      this.dp = item;
      this.kdp++;
    },
  },
};
</script>
